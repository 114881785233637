import React, { useState, useEffect } from "react";
import { useUser } from "../context/userContext";
import Confetti from "react-confetti";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firestore";

const CryptoFarming = () => {
  const FARM_DURATION = 60; // 60 seconds for testing
  const { id, miningPower, balance, setBalance } = useUser();
  const [timeRemaining, setTimeRemaining] = useState(FARM_DURATION);
  const [pointsEarned, setPointsEarned] = useState(0);
  const [displayedPoints, setDisplayedPoints] = useState(0); // For animated numbers
  const [progress, setProgress] = useState(0); // Progress in percentage
  const [showConfetti, setShowConfetti] = useState(false);
  const [isFarming, setIsFarming] = useState(false);

  useEffect(() => {
    const farmingStart = localStorage.getItem("farmingStart");
    if (farmingStart) {
      const elapsedTime = Date.now() - parseInt(farmingStart, 10);
      if (elapsedTime < FARM_DURATION * 1000) {
        const remainingTime = FARM_DURATION - Math.floor(elapsedTime / 1000);
        setTimeRemaining(remainingTime);
        setPointsEarned((elapsedTime / (FARM_DURATION * 1000)) * miningPower);
        setDisplayedPoints((elapsedTime / (FARM_DURATION * 1000)) * miningPower);
        setIsFarming(true);
      } else {
        setTimeRemaining(0);
        setPointsEarned(miningPower);
        setDisplayedPoints(miningPower);
        setIsFarming(false);
      }
    }
  }, []);

  useEffect(() => {
    let interval;
    if (isFarming && timeRemaining > 0) {
      interval = setInterval(() => {
        setTimeRemaining((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(interval);
            setProgress(100);
            setDisplayedPoints(pointsEarned);
            setIsFarming(false);
            localStorage.removeItem("farmingStart");
            return 0;
          }

          const elapsedTime = FARM_DURATION - prevTime + 1;
          const earnedPoints = (elapsedTime / FARM_DURATION) * miningPower;
          setPointsEarned(earnedPoints);
          setDisplayedPoints(earnedPoints);
          setProgress((elapsedTime / FARM_DURATION) * 100);
          return prevTime - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isFarming, timeRemaining, miningPower]);

  const startFarming = () => {
    const currentTime = Date.now();
    setIsFarming(true);
    setTimeRemaining(FARM_DURATION);
    setPointsEarned(0);
    setDisplayedPoints(0);
    setProgress(0);
    localStorage.setItem("farmingStart", currentTime);
  };

  const claimTokens = async () => {
    try {
      const newBalance = balance + pointsEarned;
      const userRef = doc(db, "telegramUsers", id.toString());
      await updateDoc(userRef, {
        balance: newBalance,
      });

      setBalance(newBalance);
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 3000);
      setPointsEarned(0);
      setDisplayedPoints(0);
      setProgress(0);
      setTimeRemaining(FARM_DURATION);
      localStorage.removeItem("farmingStart");
    } catch (error) {
      console.error("Failed to claim tokens:", error);
      alert("Failed to claim tokens. Please try again later.");
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}m ${secs
      .toString()
      .padStart(2, "0")}s`;
  };

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center bg-black relative">
      {showConfetti && <Confetti />}
      <div
        onClick={isFarming ? null : timeRemaining === 0 ? claimTokens : startFarming}
        className="relative w-[90%] max-w-md h-16 flex items-center bg-gray-900 rounded-lg overflow-hidden cursor-pointer shadow-md"
      >
        <div
          className="absolute top-0 left-0 h-full bg-yellow-500"
          style={{
            width: `${progress}%`,
            transition: "width 1s linear",
          }}
        ></div>

        <div className="relative z-10 w-full h-full flex items-center px-4">
          <span className="text-yellow-400 text-2xl">⚡</span>
          <div className="flex-1 text-center">
            {isFarming ? (
              <div className="flex justify-center items-center space-x-1 font-extrabold text-2xl">
                {(() => {
                  const formattedNumber = Math.floor(displayedPoints).toLocaleString();
                  const digits = formattedNumber.split("");

                  return digits.map((digit, index) => (
                    <span
                      key={index}
                      className="animated-digit text-white"
                      style={{
                        animation: `fall-bounce 0.6s ease-out ${index * 0.1}s`,
                        display: "inline-block",
                      }}
                    >
                      {digit}
                    </span>
                  ));
                })()}
              </div>
            ) : timeRemaining === 0 ? (
              "Claim Tokens"
            ) : (
              "Start Farming"
            )}
          </div>
          {isFarming && (
            <span className="absolute right-4 text-white font-medium text-sm">
              {formatTime(timeRemaining)}
            </span>
          )}
        </div>
      </div>
      <style>{`
        @keyframes fall-bounce {
          0% {
            transform: translateY(-150%);
            opacity: 0;
          }
          50% {
            transform: translateY(10%);
            opacity: 1;
          }
          75% {
            transform: translateY(-5%);
          }
          100% {
            transform: translateY(0%);
          }
        }
        .animated-digit {
          animation-fill-mode: both; /* Ensures the final state is preserved */
        }
      `}</style>
    </div>
  );
};

export default CryptoFarming;
